import { Alert, Button, Grid, SvgIcon, Typography } from '@mui/material';
import { useCurrentUserRelatives, useIsMobileView } from 'hooks';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusSquareIcon } from 'resources/icons/plus_square.svg';
import { ReactComponent as InfoIcon } from 'resources/icons/info.svg';
import { Gender, Person } from 'models/profile.model';
import { redesignColors } from 'resources/theme/theme.colors';
import { AppointmentPatient } from 'components/appointment/types/Patient';

const IntroductionStep: FC<{
  handleNext: () => void;
  handleIsActiveNextButton: () => void;
  setPatient: (patient: AppointmentPatient) => void;
}> = ({ handleNext, handleIsActiveNextButton, setPatient }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const relatives = useCurrentUserRelatives();

  useEffect(() => {
    handleIsActiveNextButton();
  }, []);

  const disabledButtonStyles = { background: redesignColors.grey.grey3, color: redesignColors.grey.grey2, borderColor: `${redesignColors.grey.grey3} !important` };
  return (
    <Grid item xs={12} sm={6} md={4} sx={{ margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: '25vh' }}>
      <Typography fontSize='20px' fontWeight='700' textAlign='center' pb={3}>
        {t('fertility.introduction.choose_patient')}
      </Typography>
      <Alert
        severity='info'
        sx={{ backgroundColor: redesignColors.blue.blue3, fontSize: '14px', fontWeight: 500, borderRadius: '8px', width: isMobileView ? '100%' : '440px', mb: 0.5 }}
        icon={<SvgIcon component={InfoIcon} />}
      >
        {t('fertility.opening.male_only')}
      </Alert>
      {relatives?.map((patient) => (
        <Button
          variant='outlined'
          disabled={patient.gender === Gender.FEMALE}
          sx={{ width: isMobileView ? '100%' : '440px', mt: 1.5, ...(patient.gender === Gender.FEMALE && disabledButtonStyles) }}
          onClick={() => {
            setPatient({
              id: patient.id,
              firstName: patient.first_name,
              lastName: patient.last_name,
              email: patient.email,
              phone: patient.phone,
              gender: patient.gender,
              birthDate: new Date(patient.birth_date),
              birthName: patient.birth_name,
              emailConfirm: patient.email,
              existed: true,
            });
            handleNext();
          }}
        >
          {patient?.first_name} {patient?.last_name}
        </Button>
      ))}
      <Button startIcon={<SvgIcon component={PlusSquareIcon} inheritViewBox />} sx={{ mt: 3 }} onClick={handleNext}>
        {t('fertility.introduction.add_patient')}
      </Button>
    </Grid>
  );
};

export default IntroductionStep;
